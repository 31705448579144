import qlik from './qlik';
import {dimensions, measures} from './qlik/klant/klant';
import {dimensions as elo_dimensions, measures as elo_measures} from './qlik/klant/elo';


export default [
    {
      value: 'dimensions',
      label: 'Dimensies',
      class: 'bg-primary',
      children: [
        {
          label: 'Debiteur',
          value: 'debiteur',
          children: [
            {
              label: 'Naam',
              value: 'vD_Order.Debtor.Name',
              qlik: dimensions.debtor.name,

            },
            {
              label: 'Nummer',
              value: 'vD_Order.Debtor.Number',
              qlik: dimensions.debtor.number,
            },
            {
              label: 'Groep',
              value: 'vD_Order.Debtor.Group',
              qlik: dimensions.debtor.type,
            }
          ]
        },
        {
          label: 'Order',
          value: 'order',
          children: [
            {
              value: 'vD_Order.OrderNumberDisplay',
              label: 'Zending',
              qlik: dimensions.order.ordernumberdisplay,

            },
            {
              value: 'vD_Order.Reference',
              label: 'Reference',
              qlik: dimensions.order.reference,

            },
            {
              value: 'vD_Order.Status',
              label: 'Status',
              qlik: dimensions.order.status,

            },
            {
              value: 'vD_Order.Kind',
              label: 'Soort',
              qlik: dimensions.order.kind,

            },
            {
              value: 'vD_Order.Goods.Group',
              label: 'Goederen groep',
              qlik: elo_dimensions.order.goodsgroup,

            }
          ]
        },
        {
          label: 'Laad Locatie',
          value: 'laadlocatie',
          children: [
            {
              value: 'vD_Order.LoadingLocation.Name',
              label: 'Naam',
              qlik: dimensions.loadinglocation.name,

            },
            {
              value: 'vD_Order.LoadingLocation.SearchCode',
              label: 'Zoekcode',
              qlik: dimensions.loadinglocation.searchcode,

            },
            {
              value: 'vD_Order.LoadingLocation.ZIPcode',
              label: 'Postcode',
              qlik: dimensions.loadinglocation.zipcode,

            },
            {
              value: 'vD_Order.LoadingLocation.City',
              label: 'Plaats',
              qlik: dimensions.loadinglocation.city,

            },
            {
              value: 'vD_Order.LoadingLocation.Country',
              label: 'Land',
              qlik: dimensions.loadinglocation.country,

            },
          ]
        },
        {
          label: 'Los Locatie',
          value: 'loslocatie',
          children: [
            {
              value: 'vD_Order.UnloadingLocation.Name',
              label: 'Naam',
              qlik: dimensions.unloadinglocation.name,

            },
            {
              value: 'vD_Order.UnloadingLocation.SearchCode',
              label: 'Zoekcode',
              qlik: dimensions.unloadinglocation.searchcode,

            },
            {
              value: 'vD_Order.UnloadingLocation.ZIPcode',
              label: 'Postcode',
              qlik: dimensions.unloadinglocation.zipcode,

            },
            {
              value: 'vD_Order.UnloadingLocation.City',
              label: 'Plaats',
              qlik: dimensions.unloadinglocation.city,

            },
            {
              value: 'vD_Order.UnloadingLocation.Country',
              label: 'Land',
              qlik: dimensions.unloadinglocation.country,

            }
          ]
        },
        {
          label: 'Periode',
          value: 'periode',
          children: [
            {
              value: 'vD_Year',
              label: 'Jaar',
              qlik: dimensions.period.year,

            },
            {
              value: 'vD_Quarter',
              label: 'Kwartaal',
              qlik: dimensions.period.quarter,

            },
            {
              value: 'vD_Month',
              label: 'Maand',
              qlik: dimensions.period.month,

            },
            {
              value: 'vD_Week',
              label: 'Week',
              qlik: dimensions.period.week,

            },
            {
              value: 'vD_Date',
              label: 'Datum',
              qlik: dimensions.period.date
            },
          ]
        },
      ]
    },
    {
      label: 'Meetwaarden',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Omzet',
          children: [
            {
              value: 'vE_OrderOmzet',
              label: 'Totaal',
              qlik: measures.omzet.totaal,

            }
          ]
        },
        {
          value: 'kosten',
          label: 'Kosten',
          children: [
            {
              value: 'vE_OrderKosten',
              label: 'Totaal',
              help: 'Totaal van de directe kosten.',
              qlik: measures.kosten.totaal,

            },
            {
              value: 'vE_OrderKostenCharter',
              label: 'Vracht', 
              help: 'Kosten van kilometer, uren en vaste charters',
              qlik: measures.kosten.charter,

            },
            {
              value: 'vE_OrderKostenTol',
              label: 'Tol',
              help: 'Tolkosten worden enkel vanuit RitRov meegenomen wanneer ze geregistreerd worden op een eigen auto',
              qlik: measures.kosten.toll,

            },
            {
              value: 'vE_OrderKostenWarehouse',
              label: 'Crossdock',
              help: 'Voorcalculatorische kosten vanuit RitRov voor de reservering van de directe crossdock kosten per zending',
              qlik: measures.kosten.warehouse,

            },
            {
               value: 'vE_OrderKostenOther',
               label: 'Other',
               help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder',
               qlik: measures.kosten.other,

            },
          ]
        },
        {
          value: 'aantal',
          label: 'Aantal',
          children: [
            {
              value: 'vE_OrderAantalOrders',
              label: 'Zendingen',
              help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B',
              qlik: measures.aantal.orders,

            },
            {
              value: 'vE_OrderAantalRitten',
              label: 'Ritten',
              help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. ',
              qlik: measures.aantal.ritten,

            },
            {
              value: 'vE_OrderAantalCo2WTW',
              label: 'Co2 WTW',
              help: 'Aantal KG Co2 Well To Wheel toegekend aan orders. ',
              qlik: elo_measures.aantal.co2WTW,

            },  
            {
              value: 'vE_OrderAantalCo2TTW',
              label: 'Co2 TTW',
              help: 'Aantal KG Co2 Tank To Wheel toegekend aan orders.  ',
              qlik: elo_measures.aantal.co2TTW,

            },
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              value: 'vE_KPI_OrderGoedkeur',
              label: 'OK',
              help: 'Het percentage zendingen dat goedgekeurd is. Wanneer er iets misgaat met een zending wordt deze niet meegenomen in de kerngetallen.',
              qlik: measures.kpi.goedkeur,
            },
  
            {
              value: 'vE_KPI_OrderNormMarginPercent',
              label: 'Norm Marge %',
              help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.',
              qlik: measures.kpi.normmarginpercent,

            },
            {
              value: 'vE_KPI_OrderNormMarginOverOmzet',
              label: 'Norm Marge',
              help: 'De Norm Marge interpoleert het berekende percentage over de gehele omzet.',
              qlik: measures.kpi.normmarginomzet,

            },
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Eigen Wagen',
          children: [
            {
              value: 'vE_KPI_OrderOmzetPerUur',
              label: 'Omzet / Uur', 
              help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. ',
              qlik: measures.own.omzetperuur,

            },
            {
              value: 'vE_KPI_Order_TripOmzetPerUur',
              label: 'Omzet / Uur - rit',
              help: 'De omzet / uur van de gehele rit(ten) waar deze order(s) ingezeten heeft/hebben. Het gaat hier altijd enkel om goedgekeurde ritten. ',
              qlik: measures.own.tripomzetperuur,

            },
            {
              value: 'vE_OrderNormTurnoverHour',
              label: 'Norm Omzet / Uur',
              help: 'De geconfigureerde norm op orderniveau  voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. ',
              qlik: measures.own.normomzetperuur,


            },
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Uitbesteed',
          children: [
            {
              value: 'vE_KPI_OrderTripOutsourced',
              label: '% Uitbesteed',
              help: 'Het percentage dat is uitbesteed. Indien een zending in het voorhaaltraject door een eigen wagen uitgevoerd wordt en het wegbrengen wordt uitbesteed is deze zending voor 50% uitbesteed.',
              qlik: measures.outsourced.percentage,

            },
            {
              value: 'vE_KPI_OrderCharterMarge',
              label: 'Derden Marge',
              help: 'De marge op het uitbesteede werk. Dit is de omzet minus de kosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de order goedgekeurd is. ',
              qlik: measures.outsourced.marge,

            },
            {
              value: 'vE_OrderNormCharter',
              label: 'Norm',
              help: 'De geconfigureerde norm op orderniveau  voor het uitbesteede werk De Derden Marge wordt getoetst aan deze norm. ',
              qlik: measures.outsourced.norm,

            },
          ]
        },
        {
          value: 'potency',
          label: 'Potentie',
          children: [
            {
              value: 'vE_OrderPotency',
              label: 'Totaal',
              qlik: measures.potentie.totaal,

            },
            {
              value: 'vE_OrderPotencyCommercial',
              label: 'Commercieel',
              qlik: measures.potentie.commercieel,

            },
            {
              value: 'vE_OrderPotencyOperational',
              label: 'Operationeel',
              qlik: measures.potentie.operationeel,

            },
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              value: 'vE_OrderKM',
              label: 'Totaal',
              help: 'Geeft de totale kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              qlik: measures.km.km,

            },
            {
              value: 'vE_KPI_OrderOmzetPerKM',
              label: 'Omzet / KM',
              help: 'Geeft de omzet per kilometer weer. Hierbij wordt enkel de goedgekeurde omzet afgezet tegen de goedgekeurde kilometers',
              qlik: measures.km.omzetperkm,

            },
            {
              value: 'vE_OrderNormTurnoverKM',
              label: 'Norm Omzet / KM',
              help: 'De geconfigureerde norm op orderniveau voor de Omzet / KM.',
              qlik: measures.km.normomzetperkm,

            }
          ]
        },
        {
          value: 'uren',
          label: 'Uren',
          children: [
            {
              value: 'vE_OrderUren',
              label: 'Totaal (Σ)',
              help: 'Het totaal aantal uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              qlik: measures.uren.totaal

            },
            {
              value: 'vE_OrderUrenLoading',
              label: 'Laden (Σ)',
              help: 'Het totaal aantal laaduren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              qlik: measures.uren.loading

            },
            {
              value: 'vE_OrderUrenUnloading',
              label: 'Lossen (Σ)',
              help: 'Het totaal aantal losuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              qlik: measures.uren.unloading


            },
            {
              value: 'vE_OrderUrenDriving',
              label: 'Rijden (Σ)',
              help: 'Het totaal aantal rijuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              qlik: measures.uren.driving


            },
            {
              value: 'vE_OrderUrenOther',
              label: 'Overig (Σ)',
              help: 'Het totaal aantal overige uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              qlik: measures.uren.other

            },
            {
              value: 'vE_OrderUrenAvg',
              label: 'Totaal (avg)',
              help: 'Het gemiddeld aantal uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              qlik: measures.uren.avg_totaal

            },
            {
              value: 'vE_OrderUrenAvgLoading',
              label: 'Laden (avg)',
              help: 'Het gemiddeld aantal laaduren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              qlik: measures.uren.avg_loading

            },
            {
              value: 'vE_OrderUrenAvgUnloading',
              label: 'Lossen (avg)',
              help: 'Het gemiddeld aantal losuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              qlik: measures.uren.avg_unloading

            },
            {
              value: 'vE_OrderUrenAvgDriving',
              label: 'Rijden (avg)',
              help: 'Het gemiddeld aantal rijuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              qlik: measures.uren.avg_driving

            },
            {
              value: 'vE_OrderUrenAvgOther',
              label: 'Overig (avg)',
              help: 'Het gemiddeld aantal overige uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              qlik: measures.uren.avg_other

            }
          ]
        },
        {
          value: 'persize',
          label: 'Per Eenheid',
          children: [
            {
              value: 'Gecalculeerde LM',
              label: 'Gecalculeerde LM',
              children: [
                {
                  value: 'vE_OrderShipmentSize',
                  label: 'Totaal',
                  help: 'Laadmeters incl omgerekend vanuit gewicht',
                  qlik: measures.persize.shipmentsize,

                },
                {
                  value: 'vE_OrderShipmentSizeAvg',
                  label: 'Gemiddelde',
                  help: 'Gemiddelde laadmeters incl omgerekend vanuit gewicht',
                  qlik: measures.persize.shipmentsizeavg,

                },
                {
                  value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
                  label: 'Omzet / LM',
                  help: 'De totale omzet / gedeeld door het totale aantal LM.',
                  qlik: measures.persize.omzet_shipmentsize,

                },
                {
                  value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
                  label: 'Laden / LM',
                  help: 'Het aantal laadminuten per laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.',
                  qlik: measures.persize.loading_shipmentsize,

                },
                {
                  value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
                  label: 'Lossen / LM',
                  help: 'Het aantal losminuten per laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.',
                  qlik: measures.persize.unloading_shipmentsize,

                },
                {
                  value: 'vE_KPI_OrderMinutenPerZendingGrootte',
                  label: 'Tijd / LM',
                  help: 'Het aantal minuten per laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.',
                  qlik: measures.persize.time_shipmentsize,

                },
              ]
            },
            {
              value: 'lm',
              label: 'LM',
              children: [
                {
                  value: 'vE_OrderLM',
                  label: 'Totaal',
                  help: 'Het aantal laadmeters vanuit TMS',
                  qlik: measures.persize.lm,

                },
                {
                  value: 'vE_OrderLMAvg',
                  label: 'Gemiddelde',
                  help: 'Het gemiddelde aantal laadmeters vanuit TMS',
                  qlik: measures.persize.avg_lm,

                },
              ]
            },
            {
              value: 'kg',
              label: 'KG',
              children: [
                {
                  value: 'vE_OrderKG',
                  label: 'Totaal',
                  help: 'Het totaal aantal kilo\'s vanuit RitRov',
                  qlik: measures.persize.kg,


                },
                {
                  value: 'vE_OrderKGAvg',
                  label: 'Gemiddelde',
                  help: 'Het gemiddelde aantal kilo\'s vanuit RitRov',
                  qlik: measures.persize.avg_kg,


                }
              ]
            },
          ]
        },
      ]
    }
  ];
  