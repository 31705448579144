import {measures} from './qlik/qlik';
import { dimensions as ritdimensions } from '../ritten/qlik/qlik';
import { dimensions as orderdimensions, measures as ordermeasures } from '../orders/qlik/klant/klant';
import {dimensions as wij_dimensions, measures as wij_measures} from '../orders/qlik/klant/wij';
import {measures as co2measures} from './qlik/co2';
import {dimensions as wij_trajectdimensions, measures as wij_trajectmeasures } from './qlik/wij';


export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            qlik: wij_dimensions.other.group,
            new_value: 'wij_dimensions.other.group',
            label: 'Afdeling',
            value: 'vD_Order.Group',
          },
          {
            qlik: orderdimensions.order.ordernumberdisplay,
            value: 'vD_Order.OrderNumberDisplay',
            label: 'Order Nummer'
          },  
          {
            label: 'Klant',
            value: 'klant',
            children: [
              {
                qlik: orderdimensions.debtor.name, 
                label: 'Naam',
                value: 'vD_Order.Debtor.Name'
              },
              {
                qlik: orderdimensions.debtor.number,
                label: 'Nummer',
                value: 'vD_Order.Debtor.Number'
              }
            ]
          },
          {
            label: 'Laadlocatie',
            value: 'laadlocatie',
            children: [
              {
                qlik: orderdimensions.loadinglocation.id,
                value: 'vD_Order.LoadingLocation.ID',
                label: 'ID'
              },
              {
                qlik: orderdimensions.loadinglocation.name,
                value: 'vD_Order.LoadingLocation.Name',
                label: 'Naam'
              },
              {
                qlik: orderdimensions.loadinglocation.adress,
                value: 'vD_Order.LoadingLocation.Adress',
                label: 'Adres'
              },
              {
                qlik: orderdimensions.loadinglocation.zipcode,
                value: 'vD_Order.LoadingLocation.ZIPcode',
                label: 'Postcode'
              },
              {
                qlik: orderdimensions.loadinglocation.city,
                value: 'vD_Order.LoadingLocation.City',
                label: 'Plaats'
              },
              {
                qlik: orderdimensions.loadinglocation.district,
                value: 'vD_Order.LoadingLocation.District',
                label: 'Gemeente'
              },
              {
                qlik: orderdimensions.loadinglocation.province,
                value: 'vD_Order.LoadingLocation.Province',
                label: 'Provincie'
              },
              {
                qlik: orderdimensions.loadinglocation.country,
                value: 'vD_Order.LoadingLocation.Country',
                label: 'Land'
              },
              {
                qlik: orderdimensions.loadinglocation.land,
                value: 'vD_Order.LoadingLocation.Land',
                label: 'Land (ISO)'
              },
            ]
          },
          {
            label: 'Loslocatie',
            value: 'loslocatie',
            children: [
              {
                qlik: orderdimensions.unloadinglocation.id,
                value: 'vD_Order.UnloadingLocation.ID',
                label: 'ID'
              },
              {
                qlik: orderdimensions.unloadinglocation.name,
                value: 'vD_Order.UnloadingLocation.Name',
                label: 'Naam'
              },
              {
                qlik: orderdimensions.unloadinglocation.adress,
                value: 'vD_Order.UnloadingLocation.Adress',
                label: 'Adres'
              },
              {
                qlik: orderdimensions.unloadinglocation.zipcode,
                value: 'vD_Order.UnloadingLocation.ZIPcode',
                label: 'Postcode'
              },
              {
                qlik: orderdimensions.unloadinglocation.city,
                value: 'vD_Order.UnloadingLocation.City',
                label: 'Plaats'
              },
              {
                qlik: orderdimensions.unloadinglocation.district,
                value: 'vD_Order.UnloadingLocation.District',
                label: 'Gemeente'
              },
              {
                qlik: orderdimensions.unloadinglocation.province,
                value: 'vD_Order.UnloadingLocation.Province',
                label: 'Provincie'
              },
              {
                qlik: orderdimensions.unloadinglocation.country,
                value: 'vD_Order.UnloadingLocation.Country',
                label: 'Land'
              },
              {
                qlik: orderdimensions.unloadinglocation.land,
                value: 'vD_Order.UnloadingLocation.Land',
                label: 'Land (ISO)'
              },
            ]
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            qlik: ritdimensions.trip.number,
            value: 'vD_Trip.TripNumber',
            label: 'Ritnummer'
          },
          {
            label: 'Chauffeur',
            value: 'driver',
            children: [
              {
                qlik: ritdimensions.driver.name,
                label: 'Naam',
                value: 'vD_Driver.Name'
              },
              {
                qlik: ritdimensions.driver.number, 
                label: 'Nummer',
                value: 'vD_Driver.Number'
              }
            ]
          },
          {
            label: 'Auto',
            value: 'vehicle',
            children: [
              {
                qlik: ritdimensions.trip.plangroup,
                value: 'vD_Trip.PlanGroup',
                label: 'Afdeling'
              },
              {
                qlik: ritdimensions.vehicle.license,
                label: 'Kenteken',
                value: 'vD_Vehicle.License'
              },
              {
                qlik: ritdimensions.vehicle.number,
                label: 'Nummer',
                value: 'vD_Vehicle.Number'
              }
            ]
          },
          {
            label: 'Crediteur',
            value: 'crediteur',
            children: [
              {
                qlik: ritdimensions.charter.name,
                label: 'Naam',
                value: 'vD_Trip.CreditorName'
              },
              {
                qlik: ritdimensions.charter.number,
                label: 'Nummer',
                value: 'vD_Trip.CreditorNumber'
              }
            ]
          },
          {
            label: 'Trailer',
            value: 'trailer',
            children: [
              {
                qlik: ritdimensions.trailer.number, 
                label: 'Nummer',
                value: 'vD_Trip.TrailerNumber'
              },
            ]
          },

        ]
      },
      {
        label: 'Traject',
        value: 'Traject',
        children: [
          {
            qlik: wij_trajectdimensions.traject.type,
            value: 'vD_OrderTripDetails.TrajectType',
            label: 'Type'
          },
          {
            qlik: wij_trajectdimensions.traject.loadingsorter,
            value: 'vD_OrderTripDetails.Loading.Volgorde',
            label: 'Laadvolgorde'
          },
          {
            qlik: wij_trajectdimensions.traject.unloadingsorter,
            value: 'vD_OrderTripDetails.Unloading.Volgorde',
            label: 'Losvolgorde'
          },
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            qlik: orderdimensions.period.year,
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            qlik: orderdimensions.period.quarter,
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            qlik: orderdimensions.period.month,
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            qlik: orderdimensions.period.week,
            value: 'vD_Week',
            label: 'Week'
          },
          {
            qlik: orderdimensions.period.date,
            value: 'vD_Date',
            label: 'Datum'
          },
          {
            qlik: orderdimensions.period.weekday,
            value: 'vD_Weekday',
            label: 'Weekday'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            qlik: measures.omzet.totaal,
            value: 'vE_OrderTripOmzet',
            label: 'Totaal'
          },
          {
            qlik: measures.omzet.freight,
            value: 'vE_OrderTripOmzet.Vracht',
            label: 'Vracht'
          },
          {
            qlik: measures.omzet.fuel,
            value: 'vE_OrderTripOmzet.Fuel',
            label: 'Brandstof'
          },
          {
            qlik: measures.omzet.other,
            value: 'vE_OrderTripOmzet.Other',
            label: 'Overig'
          }
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            qlik: measures.kosten.totaal,
            value: 'vE_OrderTripKosten',
            label: 'Totaal',
          },
          {
            qlik: measures.kosten.charter,
            value: 'vE_OrderTripKostenCharter',
            label: 'Derden',
          },
          {
            qlik: measures.kosten.toll,
            value: 'vE_OrderTripKostenTol',
            label: 'Tol',
          },
          {
            qlik: measures.kosten.codriver,
            value: 'vE_OrderTripKostenCoDriver',
            label: 'Bijrijder',
          },
          {
            qlik: measures.kosten.warehouse,
            value: 'vE_OrderTripKostenWarehouse',
            label: 'Warehouse',
          },
          {
            qlik: measures.kosten.other,
            value: 'vE_OrderTripKostenOther',
            label: 'Other',
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            qlik: measures.aantal.orders,
            value: 'vE_OrderTripAantalOrders',
            label: 'Zendingen',
          },
          {
            qlik: measures.aantal.ritten,
            value: 'vE_OrderTripAantalRitten',
            label: 'Ritten',
          },
          {
            qlik: wij_trajectmeasures.aantal.trajecten,
            value: 'vE_OrderTripAantalOrderTrips',
            label: 'Trajecten',
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            qlik: measures.kpi.goedkeur,
            value: 'vE_KPI_OrderTripGoedkeur',
            label: 'OK'
          },
          {
            qlik: measures.kpi.normmarginpercent,
            value: 'vE_KPI_OrderTripNormMarginPercent',
            label: 'Marge %'
          },
          {
            qlik: measures.kpi.normmarginomzet,
            value: 'vE_KPI_OrderTripNormMarginOverOmzet',
            label: 'Marge'
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            qlik: wij_trajectmeasures.own.omzetperuur,
            value: 'vE_KPI_OrderTripOmzetPerUur',
            label: 'Omzet / Uur'
          },
          {
            qlik: measures.own.normomzetperuur,
            value: 'vE_OrderTripNormTurnoverHour',
            label: 'Norm Omzet / Uur'
          }
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            qlik: measures.outsourced.percentage,
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed'
          },
          {
            qlik: measures.outsourced.marge,
            value: 'vE_KPI_OrderTripCharterMarge',
            label: 'Derden Marge'
          },
          {
            qlik: measures.outsourced.norm,
            value: 'vE_OrderTripNormCharter',
            label: 'Norm'
          },
        ]
      },
      {
        value: 'potency',
        label: 'Potentie',
        children: [
          {
            qlik: measures.potentie.totaal,
            value: 'vE_OrderTripPotency',
            label: 'Totaal'
          },
          {
            qlik: measures.potentie.commercieel,
            value: 'vE_OrderTripPotencyCommercial',
            label: 'Commercieel'
          },
          {
            qlik: measures.potentie.operationeel,
            value: 'vE_OrderTripPotencyOperational',
            label: 'Operationeel'
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            qlik: measures.km.km,
            value: 'vE_OrderTripKM',
            label: 'Totaal'
          },
          {
            qlik: measures.km.omzetperkm,
            value: 'vE_KPI_OrderTripOmzetPerKM',
            label: 'Omzet / KM'
          },
          {
            qlik: measures.km.normomzetperkm,
            value: 'vE_OrderTripNormTurnoverKM',
            label: 'Norm Omzet / KM'
          },
        ]
      },
      {
        value: 'CO2',
        label: 'CO2',
        children: [
          {
            qlik: co2measures.co2.rekenkilometers,
            value: 'vE_TripCo2KM',
            label: 'Rekenkilometers',
            help: 'De kilometers voor de CO2, indien niet aanwezig worden zijn deze geinterpoleerd op basis van de afstand en zendinggrootte'
          },
          {
            qlik: co2measures.co2.verbruikperkm,
            value: 'vE_TripCo2Verbruik',
            label: 'Vebruik per km',
            help: 'Het brandstofverbruik per kilometer, op dit moment een vaste norm vastgesteld op 0.3 liter per km'
          },
          {
            qlik: co2measures.co2.litersbrandstof,
            value: 'vE_TripCo2L',
            label: 'Liters Brandstof',
            help: 'Het aantal liters brandstof op basis van het aantal rekenkilometers in combinatie met het brandstofverbruik'
          },
          {
            qlik: co2measures.co2.uitstootfactorwtw,
            value: 'vE_TripCo2FactorWTW',
            label: 'Uitstoot factor WTW',
            help: 'De factor voor de uitstoot van een liter brandstof WellToWheel (WTW)'
          },
          {
            qlik: co2measures.co2.uitstootfactorttw,
            value: 'vE_TripCo2FactorTTW',
            label: 'Uitstoot factor TTW',
            help: 'De factor voor de uitstoot van een liter brandstof TankToWheel (TTW)'
          },
          {
            qlik: co2measures.co2.co2uitstootwtw,
            value: 'vE_TripCo2TotaalWTW',
            label: 'CO2 uitstoot (WTW)',
          },
          {
            qlik: co2measures.co2.co2uitstootttw,
            value: 'vE_TripCo2TotaalTTW',
            label: 'CO2 uitstoot (TTW)',
          }
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            qlik: measures.uren.totaal,
            value: 'vE_OrderTripUren',
            label: 'Totaal (Σ)',
          },
          {
            qlik: measures.uren.loading,
            value: 'vE_OrderTripUrenLoading',
            label: 'Laden (Σ)',
          },
          {
            qlik: measures.uren.unloading,
            value: 'vE_OrderTripUrenUnloading',
            label: 'Lossen (Σ)',
          },
          {
            qlik: measures.uren.driving,
            value: 'vE_OrderTripUrenDriving',
            label: 'Rijden (Σ)',
          },
          {
            qlik: measures.uren.other,
            value: 'vE_OrderTripUrenOther',
            label: 'Overig (Σ)',
          },
          {
            qlik: measures.uren.avg_totaal,
            value: 'vE_OrderTripUrenAvg',
            label: 'Totaal (avg)',
          },
          {
            qlik: measures.uren.avg_loading,
            value: 'vE_OrderTripUrenAvgLoading',
            label: 'Laden (avg)',
          },
          {
            qlik: measures.uren.avg_unloading,
            value: 'vE_OrderTripUrenAvgUnloading',
            label: 'Lossen (avg)',
          },
          {
            qlik: measures.uren.avg_driving,
            value: 'vE_OrderTripUrenAvgDriving',
            label: 'Rijden (avg)',
          },
          {
            qlik: measures.uren.other,
            value: 'vE_OrderTripUrenAvgOther',
            label: 'Overig (avg)',      // $(wActive(Order, 'M_Uren_Rijden_A'))
          },
    ]
      },
      {
        value: 'norm uren',
        label: 'Norm uren',
        children: [
          {
            qlik: wij_trajectmeasures.normuren.totaal,
            value: 'vE_OrderTripNormUren',
            label: 'Totaal (Σ)',
          },
          {
            qlik: wij_trajectmeasures.normuren.loading,
            value: 'vE_OrderTripNormUrenLoading',
            label: 'Laden (Σ)',
          },
          {
            qlik: wij_trajectmeasures.normuren.unloading,
            value: 'vE_OrderTripNormUrenUnloading',
            label: 'Lossen (Σ)',
          },
          {
            qlik: wij_trajectmeasures.normuren.driving,
            value: 'vE_OrderTripNormUrenDriving',
            label: 'Rijden (Σ)',
          },
          {
            qlik: wij_trajectmeasures.normuren.other,
            value: 'vE_OrderTripNormUrenOther',
            label: 'Overig (Σ)',
          },
        ]
      },
      {
        value: 'persize',
        label: 'Per Eenheid',
        children: [
          
    
              {
                qlik: measures.persize.shipmentsize,
                value: 'vE_OrderTripShipmentSize',
                label: 'LM'
              },
              {
                qlik: wij_trajectmeasures.aantal.shipmentsizetotal,
                value: 'vE_OrderTripShipmentSizeTotal',
                label: 'LM (per traject)'
              },
        ]
      },
    ]
  }    
];