const measures = {
  kosten: {
    fuel: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "OrderKostenBrandstof",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderKosten(Kind={'Fuel'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 2,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & '$(vB_Fuel)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
  },
};

export {measures};