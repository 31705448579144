const dimensions = {
};
const measures = {
  own: {
    omzetperuur: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "TripOmzetPerUur",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_TripOmzetPerUur)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripOmzetPerUur)'",
          "autoSort": true,
          "cId": "MJZcvk",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_TripOmzetPerUur) < 0.59 * $(vE_TripNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_TripOmzetPerUur) <  $(vE_TripNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
},
normuren: {
  totaal: {
    "qLibraryId": "",
    "qDef": {
      "qLabel": "",
      "qDescription": "",
      "qTags": [],
      "qGrouping": "N",
      "qDef": "$(vE_TripUrenNorm)",
      "qNumFormat": {
        "qType": "U",
        "qnDec": 10,
        "qUseThou": 0,
        "qFmt": "",
        "qDec": "",
        "qThou": ""
      },
      "qRelative": false,
      "qBrutalSum": false,
      "qAggrFunc": "Expr",
      "qAccumulate": 0,
      "qReverseSort": false,
      "qActiveExpression": 0,
      "qExpressions": [],
      "qLabelExpression": "= '$(vL_OrderUrenNorm)'  & ' > ' & '$(vB_Alle)' & ' (' & '$(vB_Sum)' & ')'",
      "autoSort": true,
      "numFormatFromTemplate": true,
      "textAlign": {
        "auto": true,
        "align": "left"
      }
    },
    "qSortBy": {
      "qSortByState": 0,
      "qSortByFrequency": 0,
      "qSortByNumeric": -1,
      "qSortByAscii": 0,
      "qSortByLoadOrder": 1,
      "qSortByExpression": 0,
      "qExpression": {
        "qv": ""
      },
      "qSortByGreyness": 0
    },
    "qAttributeExpressions": [],
    "qAttributeDimensions": [],
    "qCalcCond": {
      "qv": ""
    },
    "qCalcCondition": {
      "qCond": {
        "qv": ""
      },
      "qMsg": {
        "qv": ""
      }
    }
  },
  loading: {
    "qLibraryId": "",
    "qDef": {
      "qLabel": "",
      "qDescription": "",
      "qTags": [],
      "qGrouping": "N",
      "qDef": "$(vE_TripUrenNorm(Kind={'Loading'}))",
      "qNumFormat": {
        "qType": "U",
        "qnDec": 10,
        "qUseThou": 0,
        "qFmt": "",
        "qDec": "",
        "qThou": ""
      },
      "qRelative": false,
      "qBrutalSum": false,
      "qAggrFunc": "Expr",
      "qAccumulate": 0,
      "qReverseSort": false,
      "qActiveExpression": 0,
      "qExpressions": [],
      "qLabelExpression": "= '$(vL_OrderUrenNorm)'  & ' > ' & '$(vB_Loading)' & ' (' & '$(vB_Sum)' & ')'",
      "autoSort": true,
      "numFormatFromTemplate": true,
      "textAlign": {
        "auto": true,
        "align": "left"
      }
    },
    "qSortBy": {
      "qSortByState": 0,
      "qSortByFrequency": 0,
      "qSortByNumeric": -1,
      "qSortByAscii": 0,
      "qSortByLoadOrder": 1,
      "qSortByExpression": 0,
      "qExpression": {
        "qv": ""
      },
      "qSortByGreyness": 0
    },
    "qAttributeExpressions": [],
    "qAttributeDimensions": [],
    "qCalcCond": {
      "qv": ""
    },
    "qCalcCondition": {
      "qCond": {
        "qv": ""
      },
      "qMsg": {
        "qv": ""
      }
    }
  },
  unloading: {
    "qLibraryId": "",
    "qDef": {
      "qLabel": "",
      "qDescription": "",
      "qTags": [],
      "qGrouping": "N",
      "qDef": "$(vE_TripUrenNorm(Kind={'Unloading'}))",
      "qNumFormat": {
        "qType": "U",
        "qnDec": 10,
        "qUseThou": 0,
        "qFmt": "",
        "qDec": "",
        "qThou": ""
      },
      "qRelative": false,
      "qBrutalSum": false,
      "qAggrFunc": "Expr",
      "qAccumulate": 0,
      "qReverseSort": false,
      "qActiveExpression": 0,
      "qExpressions": [],
      "qLabelExpression": "= '$(vL_OrderUrenNorm)'  & ' > ' & '$(vB_Unloading)' & ' (' & '$(vB_Sum)' & ')'",
      "autoSort": true,
      "numFormatFromTemplate": true,
      "textAlign": {
        "auto": true,
        "align": "left"
      }
    },
    "qSortBy": {
      "qSortByState": 0,
      "qSortByFrequency": 0,
      "qSortByNumeric": -1,
      "qSortByAscii": 0,
      "qSortByLoadOrder": 1,
      "qSortByExpression": 0,
      "qExpression": {
        "qv": ""
      },
      "qSortByGreyness": 0
    },
    "qAttributeExpressions": [],
    "qAttributeDimensions": [],
    "qCalcCond": {
      "qv": ""
    },
    "qCalcCondition": {
      "qCond": {
        "qv": ""
      },
      "qMsg": {
        "qv": ""
      }
    }
  },
  driving: {
    "qLibraryId": "",
    "qDef": {
      "qLabel": "",
      "qDescription": "",
      "qTags": [],
      "qGrouping": "N",
      "qDef": "$(vE_TripUrenNorm(Kind={'Driving'}))",
      "qNumFormat": {
        "qType": "U",
        "qnDec": 10,
        "qUseThou": 0,
        "qFmt": "",
        "qDec": "",
        "qThou": ""
      },
      "qRelative": false,
      "qBrutalSum": false,
      "qAggrFunc": "Expr",
      "qAccumulate": 0,
      "qReverseSort": false,
      "qActiveExpression": 0,
      "qExpressions": [],
      "qLabelExpression": "= '$(vL_OrderUrenNorm)'  & ' > ' & '$(vB_Driving)' & ' (' & '$(vB_Sum)' & ')'",
      "autoSort": true,
      "numFormatFromTemplate": true,
      "textAlign": {
        "auto": true,
        "align": "left"
      }
    },
    "qSortBy": {
      "qSortByState": 0,
      "qSortByFrequency": 0,
      "qSortByNumeric": -1,
      "qSortByAscii": 0,
      "qSortByLoadOrder": 1,
      "qSortByExpression": 0,
      "qExpression": {
        "qv": ""
      },
      "qSortByGreyness": 0
    },
    "qAttributeExpressions": [],
    "qAttributeDimensions": [],
    "qCalcCond": {
      "qv": ""
    },
    "qCalcCondition": {
      "qCond": {
        "qv": ""
      },
      "qMsg": {
        "qv": ""
      }
    }
  },
  other: {
    "qLibraryId": "",
    "qDef": {
      "qLabel": "",
      "qDescription": "",
      "qTags": [],
      "qGrouping": "N",
      "qDef": "$(vE_TripUrenNorm(Kind={'Other'}))",
      "qNumFormat": {
        "qType": "U",
        "qnDec": 10,
        "qUseThou": 0,
        "qFmt": "",
        "qDec": "",
        "qThou": ""
      },
      "qRelative": false,
      "qBrutalSum": false,
      "qAggrFunc": "Expr",
      "qAccumulate": 0,
      "qReverseSort": false,
      "qActiveExpression": 0,
      "qExpressions": [],
      "qLabelExpression": "= '$(vL_OrderUrenNorm)'  & ' > ' & '$(vB_Other)' & ' (' & '$(vB_Sum)' & ')'",
      "autoSort": true,
      "numFormatFromTemplate": true,
      "textAlign": {
        "auto": true,
        "align": "left"
      }
    },
    "qSortBy": {
      "qSortByState": 0,
      "qSortByFrequency": 0,
      "qSortByNumeric": -1,
      "qSortByAscii": 0,
      "qSortByLoadOrder": 1,
      "qSortByExpression": 0,
      "qExpression": {
        "qv": ""
      },
      "qSortByGreyness": 0
    },
    "qAttributeExpressions": [],
    "qAttributeDimensions": [],
    "qCalcCond": {
      "qv": ""
    },
    "qCalcCondition": {
      "qCond": {
        "qv": ""
      },
      "qMsg": {
        "qv": ""
      }
    }
  },
},
};

export {measures, dimensions};