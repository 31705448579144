import React from 'react';


import orderList from '../../margemonitor/components/orders/dimensions-ELO';
import ritList from '../../margemonitor/components/ritten/dimensions-ELO';
import orderTripList from '../../margemonitor/components/trajecten/dimensions-ELO';

const KM_aansluiting = React.lazy(() => import('./../../views/Maatwerk/ELO/KM-aansluiting'));
const Uren_aansluiting = React.lazy(() => import('./../../views/Maatwerk/ELO/ControleUren'));

const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  // config: {
  //   host: window.location.hostname,
  //   secure: true,
  //   //port: 443,
  //   prefix: '',
  //   appId: '66255ec4-c5d0-4b5d-b606-f92af2f859b1',
  // },
  // connections: {
  //   qlikCloud: false,
  //   vizApi: true,
  //   engineApi: true,
  //   useUniqueSessionID: 'MargeMonitor'
  // },

  config: {
    host: 'electroniclogistics.eu.qlikcloud.com',
    secure: true,
    port: '443',
    prefix: '',
    webIntegrationId: 'wCBP77b7XRyz3raFXJXRtRUWeFe6klI7',  
    appId: "66255ec4-c5d0-4b5d-b606-f92af2f859b1"
  },
  connections: {
    qlikCloud: true, 
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },
  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Year)'],
            placeholder: 'Jaar',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Month)'],
            placeholder: 'Maand',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
            expanded: true, 
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Quarter)'],
            placeholder: 'Kwartaal',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Week)'],
            placeholder: 'Week',
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_WeekDay)]'],
            placeholder: 'Dag',
          }
        },   
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Date)'],
            placeholder: 'Datum',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Order Laadlocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Name)]'],
            placeholder: 'Naam',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'title',
          title: 'Order Loslocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Name)]'],
            placeholder: 'Naam',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
      ],
    },
    order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Zending',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Kenmerken'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Reference)]'],
            placeholder: 'Referentie',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter', 
          props: {
            cols: ['[$(vD_Order.Kind)]'], 
            placeholder: 'Kind', 
            showStateInDropdown: true, 
            autoSortByState: 1
          }
        },
        { 
          type: 'title',
          title: 'Debiteur'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'Number',
            showStateInDropdown: true,
          },
        }
      ],
    },
    trip: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Rit nummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Auto nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Auto naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Chauffeur nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Chauffeur naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[_ind_Trip.Outsourced]'],
            placeholder: 'Uitbesteed',
            showStateInDropdown: true,
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: [
        {
          title: true,
          name: '',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
        // {
        //   title: true,
        //   name: 'Maatwerk',
        //   wrapper: {
        //     element: '',
        //     attributes: {},
        //   },
        // },
        // {
        //   name: 'KM aansluiting',
        //   url: '/frv/km-aansluiting',
        //   icon: 'fa fa-check',
        // },
        // {
        //   name: 'Uren aansluiting',
        //   url: '/frv/uren-aansluiting',
        //   icon: 'fa fa-clock',
        // },
      ],
  },
  routes: [
    { path: '/frv/km-aansluiting', name: 'FRV / KM aansluiting', component: KM_aansluiting },
    { path: '/frv/uren-aansluiting', name: 'FRV / Uren aansluiting', component: Uren_aansluiting },
  ],
  fieldSettings:{
    period: 'vD_Month'
  },
  selectionBar: 'Nebula',
  rollingPeriod: false,
  defaultSelections: [
    {
      field: 'Jaar',
      expression: '=year(today())'
    }
  ]
};

export default options;
