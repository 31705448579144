import qlik from '../orders/qlik';
import {dimensions, measures} from './qlik/qlik';

export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Chauffeur',
        value: 'driver',
        children: [
          {
            label: 'Naam',
            value: 'vD_Driver.Name',
            qlik: dimensions.driver.name
          },
          {
            label: 'Nummer',
            value: 'vD_Driver.Number',
            qlik: dimensions.driver.number

          }
        ]
      },
      {
        label: 'Auto',
        value: 'vehicle',
        children: [
          {
            label: 'Nummer',
            value: 'vD_Vehicle.Number',
            qlik: dimensions.vehicle.number

          },
          {
            label: 'Naam',
            value: 'vD_Vehicle.Name',
            qlik: dimensions.vehicle.name

          },
          {
            label: 'Type',
            value: 'vD_Trip.Vehicle.Type',
            qlik: dimensions.vehicle.type

          },
        ]
      },
      {
        label: 'Crediteur',
        value: 'crediteur',
        children: [
          {
            label: 'Nummer',
            value: 'vD_Trip.Creditor.Number',
            qlik: dimensions.charter.number

          },
          {
            label: 'Naam',
            value: 'vD_Trip.Creditor.Name',
            qlik: dimensions.charter.name

          },
        ]
      },
      {
        label: 'Trailer',
        value: 'trailer',
        children: [
          {
            label: 'Nummer',
            value: 'vD_Trip.Trailer.Number',
            qlik: dimensions.trailer.number

          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            value: 'vD_Trip.TripNumber',
            label: 'Rit Nummer',
            qlik: dimensions.trip.number
          },
        ]
      },
      {
        label: 'Start Locatie',
        value: 'laadlocatie',
        children: [
          {
            value: 'vD_Trip.StartLocation.Name',
            label: 'Naam',
            qlik: dimensions.startlocation.name

          },
          {
            value: 'vD_Trip.StartLocation.SearchCode',
            label: 'Zoekcode',
            qlik: dimensions.startlocation.searchcode

          },
          {
            value: 'vD_Trip.StartLocation.ZIPcode',
            label: 'Postcode',
            qlik: dimensions.startlocation.zipcode

          },
          {
            value: 'vD_Trip.StartLocation.City',
            label: 'Plaats',
            qlik: dimensions.startlocation.city

          },
          {
            value: 'vD_Trip.StartLocation.Country',
            label: 'Land',
            qlik: dimensions.startlocation.country

          }
        ]
      },
      {
        label: 'Eind Locatie',
        value: 'loslocatie',
        children: [
          {
            value: 'vD_Trip.EndLocation.Name',
            label: 'Name',
            qlik: dimensions.endlocation.name

          },
          {
            value: 'vD_Trip.EndLocation.SearchCode',
            label: 'Zoekcode',
            qlik: dimensions.endlocation.searchcode
          },
          {
            value: 'vD_Trip.EndLocation.ZIPcode',
            label: 'Postcode',
            qlik: dimensions.endlocation.zipcode

          },
          {
            value: 'vD_Trip.EndLocation.City',
            label: 'Plaats',
            qlik: dimensions.endlocation.city

          },
          {
            value: 'vD_Trip.EndLocation.Country',
            label: 'Land',
            qlik: dimensions.endlocation.country

          }
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'vD_Year',
            label: 'Jaar',
            qlik: dimensions.period.year

          },
          {
            value: 'vD_Quarter',
            label: 'Kwartaal',
            qlik: dimensions.period.quarter

          },
          {
            value: 'vD_Month',
            label: 'Maand',
            qlik: dimensions.period.month

          },
          {
            value: 'vD_Week',
            label: 'Week',
            qlik: dimensions.period.week

          },
          {
            value: 'vD_Date',
            label: 'Datum',
            qlik: dimensions.period.date

          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            value: 'vE_TripOmzet',
            label: 'Totaal',
            qlik: measures.omzet.totaal

          }
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'vE_TripKosten',
            label: 'Totaal',
            qlik: measures.kosten.totaal

          },
          {
            value: 'vE_TripKostenCharter',
            label: 'Vracht',
            qlik: measures.kosten.charter

          },
          {
            value: 'vE_TripKostenTol',
            label: 'Tol',
            qlik: measures.kosten.toll

          },
          {
            value: 'vE_TripKostenWarehouse',
            label: 'Crossdock',
            qlik: measures.kosten.warehouse

          },
          {
             value: 'vE_TripKostenOther',
             label: 'Other',
             qlik: measures.kosten.other

          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            value: 'vE_TripAantalOrders',
            label: 'Zendingen',
            qlik: measures.aantal.orders
          },
          {
            value: 'vE_TripAantalRitten',
            label: 'Ritten',
            qlik: measures.aantal.ritten
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'vE_KPI_TripGoedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur,

          },

          {
            value: 'vE_KPI_TripNormMarginPercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent,
          },
          {
            value: 'vE_KPI_TripNormMarginOverOmzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet,
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'vE_KPI_TripOmzetPerUur',
            label: 'Omzet / Uur',
            qlik: measures.own.omzetperuur,

          },
          {
            value: 'vE_TripNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            qlik: measures.own.normomzetperuur,

          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'vE_KPI_TripTripOutsourced',
            label: '% Uitbesteed',
            qlik: measures.outsourced.percentage,

          },
          {
            value: 'vE_KPI_TripCharterMarge',
            label: 'Derden Marge',
            qlik: measures.outsourced.marge,

          },
          {
            value: 'vE_TripNormCharter',
            label: 'Norm',
            qlik: measures.outsourced.norm,

          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'vE_TripKM',
            label: 'Totaal',
            qlik: measures.km.km,

          },
          {
            value: 'vE_KPI_TripOmzetPerKM',
            label: 'Omzet / KM',
            qlik: measures.km.omzetperkm,

          },
          {
            value: 'vE_TripNormTurnoverKM',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm,

          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'vE_TripUren',
            label: 'Totaal (Σ)',
            qlik: measures.uren.totaal,
          },
          {
            value: 'vE_TripUrenLoading',
            label: 'Laden (Σ)',
            qlik: measures.uren.loading,

          },
          {
            value: 'vE_TripUrenUnloading',
            label: 'Lossen (Σ)',
            qlik: measures.uren.unloading,

          },
          {
            value: 'vE_TripUrenDriving',
            label: 'Rijden (Σ)',
            qlik: measures.uren.driving,

          },
          {
            value: 'vE_TripUrenOther',
            label: 'Overig (Σ)',
            qlik: measures.uren.other,

          },
          {
            value: 'vE_TripUrenAvg',
            label: 'Totaal (avg)',
            qlik: measures.uren.avg_totaal,

          },
          {
            value: 'vE_TripUrenAvgLoading',
            label: 'Laden (avg)',
            qlik: measures.uren.avg_loading,

          },
          {
            value: 'vE_TripUrenAvgUnloading',
            label: 'Lossen (avg)',
            qlik: measures.uren.avg_unloading,

          },
          {
            value: 'vE_TripUrenAvgDriving',
            label: 'Rijden (avg)',
            qlik: measures.uren.avg_driving,

          },
          {
            value: 'vE_TripUrenAvgOther',
            label: 'Overig (avg)',
            qlik: measures.uren.avg_other
          }
        ]
      }
    ]
  }
];
